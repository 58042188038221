





























import { Observer } from 'mobx-vue'
import { Component, Vue } from 'vue-property-decorator'

@Observer
@Component({
  components: {
    'referral-earned': () => import('@/modules/referral/components/referral-earned.vue'),
    'referral-recent-user': () => import('@/modules/referral/components/referral-recent-user.vue'),
    'referral-link': () => import('@/modules/referral/components/referral-link.vue'),
  },
})
export default class extends Vue {}
